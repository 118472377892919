<template>
<!--  used -->
<el-col :span="8" class="coupon">
    <div class="coupon_header">
      {{couponItem.couponName}} 

      <span>{{Number(couponItem.couponType)==0?'通用券':Number(couponItem.couponType)==1?'单品券':Number(couponItem.couponType)==2?'多品券':Number(couponItem.couponType)==4?'未购券':'品类券'}}</span>
        <div class="dashed"></div> 
    </div>   
    <div  class="coupon_main">
        <div class="coupon-left">
            <p v-if="couponItem.promotionMethod==0"> 
               <span><sup>￥</sup>{{ couponItem.promotionValue }}</span>
            </p>
            <p v-if="couponItem.promotionMethod==1"> 
              {{ couponItem.promotionValue }}<sub>折</sub>
            </p>
        </div>

        <div class="coupon-right">
            <div class="desMsg">

              <!-- {{couponItem.useCondition}}   {{couponItem.couponType}} -->
                <!-- 无门槛 --><span class="numMsg"  v-if="couponItem.useCondition==0">全场商品可用  {{ couponItem.canReceiveNum }}张</span>
                <!-- 满减 --><span class="numMsg"  v-if="couponItem.useCondition==1">满{{ couponItem.useAmountCondition }}元可用 {{ couponItem.canReceiveNum }}张</span>
                <!-- 数量 --><span class="numMsg"  v-if="couponItem.useCondition==2">
                   <span v-if="couponItem.couponType==4">满{{ couponItem.useNumCondition }}个未购商品可用 {{ couponItem.canReceiveNum }}张</span>
                   <span v-else>满{{ couponItem.useNumCondition }}个品种可用 {{ couponItem.canReceiveNum }}张</span>   
                </span>
            </div>
            <div class="date" v-if="couponItem.validEndTimeFlag>0">
              领取后{{couponItem.validEndTimeFlag}}天失效
            </div>
            <div class="date" v-else>{{couponItem.validStartTime.substring(0,10).replace(/-/g, '.')}}-{{couponItem.validEndTime.substring(0,10).replace(/-/g, '.')}}</div>
            <div class="getCouponBtn">
              <span v-if="couponItem.canReceiveNum>0"   @click="getCouponFun(couponItem.id)">立即领取</span>
               <span v-if="couponItem.canReceiveNum==0" class="used">已领取</span>
            </div>
        </div>
       
    </div>
    
  </el-col>
</template>
<script>
import { GetCoupon } from "api/carToOrder.js";
export default {
  name: "CouponItem",
    inject:['reload'],
  data() {
    return {
     
    };
  },
  props:{
          couponItem:{
              type:Object
            }
           
    },
  methods: {
    // 手动领取优惠券
    getCouponFun(couponId) {
      GetCoupon(couponId).then((data) => {
          if(data.data.code==200){
             this.$message({
                message: data.data.msg,
                type: 'success'
              });
              this.reload();
          }else{
            this.$message({
              message: data.data.msg,
              type: "warning",
            });
        }
      })
    },
  },
  created() {
  },
  components: {},
};
</script>
<style lang="less" scoped>
@import "~style/index.less";

.coupon {
  margin-bottom: 20px;
  margin-right: 13px;
  width: calc(33% - 5px);
  height: 170px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 7px 0px rgba(160, 160, 160, 0.15);
  // display: flex;

  .coupon_header{
    height: 40px;
    line-height: 38px;
    text-indent: 20px;
    width: 100%;
    background:linear-gradient(to right, #ED4046, #FF6A48); 
    color:#fff;
    .dashed {
      height:2px;
      background: linear-gradient(to right, #fff, #fff 13px, transparent 5px, transparent);
      background-size: 18px 100%;
    }
    span{
      text-indent:0;
      float: right;
      background: #FF937B;
      margin-top:6px;
        margin-right:20px;
      height:24px;
      line-height:24px;
      display: block;
      padding:0 12px;
      border-radius: 13px 13px 13px 0px;
    }
  }

  .coupon_main{
    width: 100%;
    height:130px;
    background: #fff;
  }
  .coupon-left{
    width:50%;
    float: left;
    p{
      line-height:120px;
      height: 120px;
      text-align: center;
      color: #FC6247 ;
      font-size:40px;
      font-weight: bold;
      span{
        position: relative;
         sup{
           position: absolute;
            top: -40px;
            left: -24px;
           font-size:24px;
          // vertical-align:super;
          }
      }
      sub{
        font-size:18px;
        // vertical-align:sub;
      }
     
    }
  }
  .coupon-right{
    width:50%;
    padding: 20px 0;
    float: right;
 
    .desMsg{
        color: #FC6247;
        font-size:13px;
        line-height:24px;
    }
    .date{
       font-size:13px;
       line-height:24px;
       color: #999999;
    }
    .getCouponBtn{
        margin-top: 10px;
      span{ 
         cursor: pointer;
          background-color: #FFD303;
          flex: 1;
          border-radius: 15px;
          padding:5px 16px;
          background: linear-gradient(to right, #FF6D15,#FCB24F);
          color: #000;
            font-size: 16px;
          &.used{
            color: #666;
            font-weight: bold;
            line-height: 30px;
            background: #ccc;
          }

      }
    }
  }
}

.coupon:after,
.coupon:before {
  box-shadow: 0 0px 3px rgb(206 206 212);
    transform: rotate(3deg);
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  z-index: 2;
  top:95px;
  // left: 228px;
  background: #f1f1f1;
  border-radius: 10px;
   border: 1px solid #EEEEEE;
}
.coupon:after {
  // bottom: -10px;
  right: -10px;
}
.coupon:before {
  // top: -10px;
  left:  -10px;
}



// .coupon.used {
//   .coupon-left {
//     .dataMsg {
//       color: #999;
//       font-size: 16px;
//       position: absolute;
//       bottom: 30px;
//       text-align: center;
//       width: 100%;
//     }
//   }
//   .coupon-left:after {
//     background-image: radial-gradient(circle at right, #ccc 6px, transparent 0);
//   }
//   .coupon-right {
//     background: #ccc;
//     border-left: none;
//   }
// }
</style>
